<!-- 活动详情 -->

<template>
	<div>
		<w-dialog ref="addDialog" class="add-dialog" title="活动详情" width="62%" btnWidth="140px" top="10vh"
			:hideFooter="true">
			<el-row :gutter="20">
				<el-col :span="16">
					<div class="s-m-b">
						<el-divider direction="vertical" class="vertical" />
						<span class="top-title">基本信息</span>
					</div>

					<el-row :gutter="20">
						<el-col :span="12">
							<div class="basic-info">
								<span>活动名称：</span>
								<span class="text-black">{{ detailDatas.a_name }}</span>
							</div>
						</el-col>
						<el-col :span="12">
							<div class="basic-info">
								<span>活动时间：</span>
								<span
									class="text-black">{{ detailDatas.a_stime_text }}至{{ detailDatas.a_etime_text }}</span>
							</div>
						</el-col>
						<el-col :span="12">
							<div class="basic-info">
								<span>活动单位：</span>
								<span class="text-black">{{ detailDatas.a_unit == '1' ? '平台' : '商家' }}</span>
							</div>
						</el-col>
						<el-col :span="12">
							<div class="basic-info">
								<span>活动类型：</span>
								<span class="text-black">{{ detailDatas.at_name }}</span>
							</div>
						</el-col>
						<el-col :span="12">
							<div class="basic-info">
								<span>抽奖用户：</span>
								<span
									class="text-black">{{ detailDatas.ac_target == '0' ? '不限' : detailDatas.ac_target == '1' ? '新用户' : '老用户' }}</span>
							</div>
						</el-col>
						<el-col :span="12">
							<div class="basic-info">
								<span>抽奖次数：</span>
								<span class="text-black">{{ detailDatas.ac_counttype == '1' ? '单日最高' : '累计最高' }}</span>
							</div>
						</el-col>
						<el-col :span="12">
							<div class="basic-info">
								<span>{{ detailDatas.ac_counttype == '1' ? '单日' : '累计' }}最高次数：</span>
								<span class="text-black">{{ detailDatas.ac_count }}次</span>
							</div>
						</el-col>
						<el-col :span="12">
							<div class="basic-info">
								<span>奖品：</span>
								<span class="text-black">{{ detailDatas.ac_prize == '1' ? '优惠券' : '其他' }}</span>
							</div>
						</el-col>
						<el-col :span="24">
							<div class="basic-info flex">
								<span class="flex-shrink">活动规则：</span>
								<textarea v-model="detailDatas.a_rule" class="text-black rule-text" readonly
									:rows="8"></textarea>
							</div>
						</el-col>

						<el-col :span="24">
							<div class="s-m-b">
								<el-divider direction="vertical" class="vertical" />
								<span class="top-title">奖品详情</span>
							</div>
							<el-table ref="tableref" :data="detailDatas.prize" stripe style="width: 100%"
								element-loading-text="数据加载中" tooltip-effect="light" height="240">
								<el-table-column prop="ap_pid" label="ID" show-overflow-tooltip />
								<el-table-column prop="ap_pname" label="优惠券" show-overflow-tooltip />
								<el-table-column prop="ap_count" label="抽奖数量" show-overflow-tooltip />
								<el-table-column prop="f_ap_probability" label="中奖概率" show-overflow-tooltip />
								<el-table-column prop="has_requ" label="中奖数量" show-overflow-tooltip />
								<el-table-column fixed="right" label="操作" width="120">
									<template #default="scope">
										<el-button type="primary" plain size="small" @click="onUsageDetails(scope.row)">使用详情</el-button>
									</template>
								</el-table-column>
							</el-table>
						</el-col>
					</el-row>
				</el-col>

				<el-col :span="8">
					<div class="flex justify-between">
						<div class="s-m-b">
							<el-divider direction="vertical" class="vertical" />
							<span class="top-title">活动信息</span>
						</div>
						<el-button type="primary" round size="small" plain class="cancle preview-btn"
							@click="onPreviewActivity">预览</el-button>
					</div>
					<div>
						<div class="s-m-b">
							<div class="s-m-b">banner图</div>
							<img-upload :limit="1" :fileList="bannerFile" suggestText="" :uploadTitle="'banner图'"
								:isShowUpload="false" :noReplaceImg="false">
							</img-upload>
						</div>

						<div class="s-m-b">
							<div class="s-m-b">缩略图</div>
							<img-upload :limit="1" :fileList="thumbnailFile" suggestText="" :uploadTitle="'缩略图'"
								:isShowUpload="false" :noReplaceImg="false">
							</img-upload>
						</div>

						<div class="s-m-b">
							<div class="s-m-b">详情图</div>
							<img-upload :limit="1" :fileList="detailedFile" suggestText="" :uploadTitle="'详情图'"
								:isShowUpload="false" :noReplaceImg="false">
							</img-upload>
						</div>
						<p class="s-m-b">分享文案</p>
						<el-input v-model="detailDatas.a_cwriter" type="textarea" rows="4" readonly
							style="background-color: #f7f7f7;">
						</el-input>
					</div>
				</el-col>
			</el-row>
		</w-dialog>

		<!-- 预览 -->
		<previewActivity ref="previewRef"></previewActivity>

		<!-- 使用统计 -->
		<useStatistics ref="refUseStatistics"></useStatistics>
	</div>
</template>

<script setup>
	import {
		ref,
		reactive,
		computed,
		watch,
	} from "vue";
	import { ActivityManage, } from "@/plugins/api.js";
	import { ElMessage, } from "element-plus";
	import { useStore } from "vuex";
	import imgUpload from "@/components/img-upload/img-upload.vue";
	import previewActivity from './previewActivity.vue';
	import useStatistics from '../../coupons/components/useStatistics.vue'

	const store = useStore();
	const menuTokens = computed(() => store.state.menuToken.menuTokens);
	const qiniuData = computed(() => store.state.Config.qiniuData);
	const authData = ref([]);
	// 监听权限
	watch(() => menuTokens.value, (data) => {
		if (data.length) {
			authData.value = data;
		}
	}, {
		deep: true,
		immediate: true,
	})
	const addDialog = ref(null);  // 弹框对象
	const detailDatas = ref({});  // 详情数据

	const tableDatas = ref([]);
	const bannerFile = ref([]);  // 上传banner图
	const thumbnailFile = ref([]);  // 上传缩略图
	const detailedFile = ref([]);  // 上传详情图
	const previewRef = ref(null);  // 预览对象
	const refUseStatistics = ref(null);  // 使用统计对象
	/**
	 * 
	 * 显示对话框
	 * 
	 * **/
	const openDetails = (row) => {
		getDetail(row.id);
		bannerFile.value = [];
		thumbnailFile.value = [];
		detailedFile.value = [];
		addDialog.value.show();
	}
	/**
	 * 
	 * 获取活动类型
	 * 
	 * **/
	const getDetail = (id) => {
		ActivityManage.getDetail({ id }).then((res) => {
			// console.log("详情", res);
			if (res.Code == 200) {
				detailDatas.value = res.Data;
				res.Data.files.forEach(item => {
					if (item.af_classify == '1') {
						bannerFile.value.push(item);
					} else if (item.af_classify == '2') {
						thumbnailFile.value.push(item);
					} else if (item.af_classify == '3') {
						detailedFile.value.push(item);
					}
				})
			} else {
				ElMessage.error(res.Message);
			}
		}).catch(() => { });
	}
	/**
	 * 
	 * 预览按钮
	 * 
	 * **/
	const onPreviewActivity = () => {
		detailDatas.value.filelist = detailDatas.value.files;
		detailDatas.value.a_time = [detailDatas.value.a_stime_text, detailDatas.value.a_etime_text];
		detailDatas.value.prizelist = detailDatas.value.prize;
		previewRef.value.openDialog(detailDatas.value);
	}
	/**
	 * 
	 * 使用详情
	 * 
	 * **/
	const onUsageDetails = (row) => {
		refUseStatistics.value.show(row.ap_pid);
	}
	defineExpose({
		openDetails,
	})
</script>

<style lang="scss">
	.flex {
		display: flex;
	}

	.justify-between {
		justify-content: space-between;
	}

	.s-m-b {
		margin-bottom: 10px;
	}

	.text-black {
		color: black;
	}

	.flex-shrink {
		flex-shrink: 0
	}

	.basic-info {
		margin-bottom: 16px;
	}

	.top-title {
		height: 32px;
		line-height: 32px;
		font-size: 16px;
		color: #000;
		font-weight: bold;
	}

	.vertical {
		border: 2px solid #1ACB95;
		margin-left: 0;
		margin-bottom: 5px;
	}

	.preview-btn {
		width: 60px;
		height: 25px;
	}

	.el-table__footer-wrapper tbody td.el-table__cell {
		background-color: #fff;
		border-color: #fff;
	}

	.el-table__fixed::before,
	.el-table::before,
	.el-table__fixed-right::before {
		background-color: #fff;
	}

	.el-row {
		border: none;
	}

	.content {
		padding: 20px;
	}

	.add-dialog {
		.el-dialog__body {
			padding: 20px 20px 30px 30px;

			.el-table__row {
				.el-table__cell {
					padding: 9px 0 !important;
				}
			}

		}
	}

	.el-textarea__inner {
		background-color: var(--search-uncheck-bg-color);
		border: none;
		color: var(--text-color);
	}

	.rule-text {
		border: none;
		font-family: Source Han Sans CN;
		font-size: 14px;
		width: 100%;
	}

	.coupon-num {
		z-index: 999;
		height: 20px;
		width: 20px;
		border-radius: 50%;
		color: #1ACB95;
		text-align: center;
		line-height: 20px;
		margin-left: 5px;
		position: absolute;
		left: 60px;
		top: 11px;
		cursor: pointer;
	}
</style>