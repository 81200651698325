<!-- 预览活动 -->

<template>
	<div class="preview-dialog" v-if="isShowPreview">
		<div class="close-icon" @click="isShowPreview = false">
			<el-icon color="#fff" :size="30">
				<Close />
			</el-icon>
		</div>
		<div class="preview">
			<div class="preview-father">
				<div class="preview-content">
					<img :src="bgImg" style="width: 303px;position: absolute;top: 0;left: 0;">
					<div class="preview-title">
						<div class="preview-name">{{ ruleForm.a_name}}</div>
						<div class="preview-time">{{ ruleForm.a_time[0] }}-{{ ruleForm.a_time[1] }}</div>
					</div>

					<div class="preview-prize">
						<div class="flex prize-lists">
							<div v-for="(item, i) in preferentialLists">
								<div class="preview-list s-m-r">
									<div class="preview-list-name">{{ item.f_coutype }}</div>
								</div>
							</div>
						</div>
						<div class="s-m-t">
							<el-button type="primary" round class="draw-btn">立即抽奖</el-button>
						</div>
						<div class="s-m-t text-red font-12 flex justify-around">
							<div>剩余次数：{{ ruleForm.ac_count }}次</div>
							<div>
								抽奖记录
								<el-icon>
									<ArrowRight />
								</el-icon>
							</div>
						</div>
					</div>

					<div class="preview-footer">
						<!-- <div class="preview-rule">{{ ruleForm.a_rule}}</div> -->
						<div class="preview-rule">
							<textarea v-model="ruleForm.a_rule" class="text-black rule-text" readonly
								:rows="20"></textarea>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { ref, nextTick, } from "vue";
	import { ActivityManage, } from "@/plugins/api.js";
	import { ElMessage, } from "element-plus";
	import { ArrowRight, Close, } from "@element-plus/icons-vue";

	const ruleForm = ref({});  // 活动表单填写数据
	const bgImg = ref('');  // 预览背景图地址
	const isShowPreview = ref(false)  // 是否显示预览
	const preferentialLists = ref([]);  // 优惠券
	/**
	 * 
	 * 打开弹框
	 * 
	 * */
	const openDialog = (data) => {
		bgImg.value = '';
		ruleForm.value = data;
		preferentialLists.value = [];
		if (ruleForm.value.filelist.length > 0) {
			ruleForm.value.filelist.forEach(item => {
				if (item.af_classify == '3') {
					bgImg.value = item.file_url;
				}
			})
		}
		if (ruleForm.value.prizelist.length == 0) {
			preferentialLists.value.push({});
		} else {
			preferentialLists.value = ruleForm.value.prizelist;
		}
		isShowPreview.value = true;
	};
	defineExpose({
		openDialog
	})
</script>

<style lang="scss" scoped>
	.preview-dialog {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, .5);
		z-index: 9999;
		padding: 40px 0;

		.close-icon {
			position: absolute;
			right: 30%;
			top: 10px;
			cursor: pointer;
			font-size: 26px;
		}

		.preview {
			margin-left: 40%;
			width: 350px;
			height: 700px;
			text-align: center;
			background: url(~@/assets/img/yl-bg.png);
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
		}

		.preview-father {
			padding: 20px 0;
			height: 100%;
			padding-top: 56px;
			padding-bottom: 22px;
		}

		.preview-content {
			position: relative;
			width: 303px;
			height: 100%;
			background-size: 100% 100%;
			/* 确保背景图像被调整大小以完全覆盖元素 */
			background-position: center;
			/* 居中显示背景图像 */
			background-repeat: no-repeat;
			/* 取消背景图像的重复平铺效果 */
			overflow: auto;
			scrollbar-width: none;
			border-radius: 0 0 30px 30px;
			margin-left: 24px;
		}

		::-webkit-scrollbar {
			width: 0 !important;
		}

		::-webkit-scrollbar {
			width: 0 !important;
			height: 0;
		}

		.preview-title {
			position: relative;
			top: 80px;
		}

		.preview-name {
			font-size: 22px;
			font-weight: bold;
			color: #fff;
			padding: 0 16px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.preview-time {
			margin-top: 20px;
			color: #fff;
			font-size: 13px;
		}

		.preview-prize {
			position: relative;
			top: 340px;
		}

		.preview-list {
			background: url(~@/assets/img/prize-bg.png);
			width: 60px;
			height: 75px;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
		}

		.prize-lists {
			margin: 0 25px;
			overflow-x: auto;
			height: 92px;
		}

		.preview-list-name {
			position: relative;
			top: 55px;
			padding: 0 5px;
			max-width: 90px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			color: #fff;
			font-size: 9px;
		}

		.preview-footer {
			position: relative;
			top: 425px;
		}

		.preview-rule {
			padding: 0 15px 10px 20px;
			overflow-y: auto;
			max-height: 245px;
			text-align: start;
			color: #6B1203;
			font-size: 12px;
		}

		.draw-btn {
			background: #FD5F30;
			border: none;
			width: 185px;
			font-size: 20px;
			cursor: text;
		}

		.flex {
			display: flex;
		}

		.s-m-b {
			margin-bottom: 10px;
		}

		.x-m-r {
			margin-right: 10px;
		}

		.s-m-t {
			margin-top: 10px;
		}

		.m-m-t {
			margin-top: 20px;
		}

		.text-red {
			color: #FF470F;
		}

		.font-12 {
			font-size: 12px;
		}

		.justify-around {
			justify-content: space-around;
		}

		.rule-text {
			border: none;
			font-family: Source Han Sans CN;
			font-size: 14px;
			width: 100%;
			background-color: transparent;
		}
	}
</style>