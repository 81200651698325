<!-- 活动管理 -->

<template>
	<div class="activity-announc main-cnt">
		<div class="content">
			<common-table ref="roleTable" tableHeight="calc(100vh - 325px)" :apiName="ActivityManage.getActivityLists"
				:columns="tableColumns" :filters="filters" @statusChange="statusChange" @onDetails="onDetails"
				@onDownloadCode="onDownloadCode" @onCopyLink="onCopyLink">
				<template #operate v-if="authData.indexOf('n_xlW1D9nVrJZ6RaViZchqAdxSjINI') != -1">
					<el-button type="primary" round @click="onAddActivityBtn">
						<el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
						新增活动
					</el-button>
				</template>

				<template #copyLink="{row}">
					<el-button @click="onCopyLink(row)" class="copy-btn">复制
					</el-button>
				</template>
			</common-table>
		</div>

		<!-- 新增活动对话框 -->
		<addActivity ref="refActivity" :tableLoad="tableLoad"></addActivity>

		<!-- 详情 -->
		<activityDetails ref="detailRef"></activityDetails>
	</div>
</template>

<script setup>
	import {
		ref,
		onMounted,
		computed,
		watch,
	} from "vue";
	import { ActivityManage, } from "@/plugins/api.js";
	import { ElMessage, } from "element-plus";
	import { useStore } from "vuex";
	import addActivity from '../components/addActivity.vue';
	import activityDetails from '../components/activityDetails.vue';
	import ClipboardJS from 'clipboard';

	const store = useStore();
	const menuTokens = computed(() => store.state.menuToken.menuTokens);
	const authData = ref([]);
	// 监听权限
	watch(() => menuTokens.value, (data) => {
		if (data.length) {
			authData.value = data;
		}
	}, {
		deep: true,
		immediate: true,
	})
	/** 筛选条件列表 */
	const filters = ref([
		{
			filterType: "select",
			name: "a_type",
			value: "",
			placeholder: "请选择活动类型",
			options: [],
			val: "id",
			lab: "at_name",
		},
		{
			filterType: "select",
			name: "status",
			value: "",
			placeholder: "请选择活动状态",
			options: [
				{ id: 1, name: '未开始' },
				{ id: 2, name: '进行中' },
				{ id: 3, name: '已过期' },
			],
			val: "id",
			lab: "name",
		},
		{
			filterType: "search",
			name: "keywords",
			value: "",
			placeholder: "请输入活动名称",
		},
	]);
	/** 表格对象 */
	const roleTable = ref(null);
	const refActivity = ref(null);  // 新增活动弹框对象
	const detailRef = ref(null);  // 详情对象

	/** 表格配置数据 */
	const tableColumns = ref([
		{
			prop: "a_name",
			label: "活动名称",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "f_a_stime",
			prop2: "f_a_etime",
			label: "活动时间",
			type: "connect",
			connectText: "-",
			color: "--text-color",
			showTooltip: true,
			minWidth: "320"
		},
		{
			prop: "at_name",
			label: "活动类型",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "f_ac_target",
			label: "抽奖用户",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "f_ac_prize",
			label: "奖品",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "f_a_status_txt",
			label: "活动状态",
			showTooltip: true,
		},
		{
			prop: "a_status",
			type: "switch",
			label: "状态",
			token: "n_JY68cPPvTUfJcKb8jUSRIWlhWmfA",
		},
		{
			prop: "f_a_unit",
			label: "活动单位",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "u_name",
			label: "创建人",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "f_a_ctime",
			label: "创建时间",
			color: "--text-color",
			showTooltip: true,
		},
		{
			type: "operation",
			prop: "",
			label: "操作",
			minWidth: 270,
			bottons: [
				{
					name: "详情",
					action: "onDetails",
					token: "n_OlF4auSAnCyX2V3uOwMNrnvJFxay",
				},
				{
					name: "复制链接",
					action: "copyLink",
					type: "customRender",
				},
				{
					name: "二维码",
					action: "onDownloadCode",
					token: "n_hJsLu8tbjBCvznQjvnJykWEYuTSI",
				}
			],
		},
	]);
	/**
	 * 
	 * 获取活动类型
	 * 
	 * **/
	const getTypeLists = () => {
		ActivityManage.getTypeLists({}).then((res) => {
			if (res.Code == 200) {
				filters.value[0].options = res.Data ? res.Data : [];
			}
		});
	}
	/**
	 * 
	 * 新增活动按钮
	 * 
	 * **/
	const onAddActivityBtn = () => {
		refActivity.value.openAddActivity();
	}
	/**
	 * 
	 * 修改当前状态
	 * 
	 * */
	const statusChange = (row) => {
		let params = {
			id: row.id,
			a_status: row.a_status == 1 ? 2 : 1,
		};
		ActivityManage.setStatus(params).then((res) => {
			if (res.Code === 200) {
				ElMessage.success("状态修改成功！");
				roleTable.value.tableLoad(); // 重新获取列表数据
			} else {
				ElMessage.error(res.Message);
			}
		});
	};
	/**
	 * 
	 * 查看详情
	 * 
	 * */
	const onDetails = (row) => {
		detailRef.value.openDetails(row);
	}
	/**
	 * 
	 * 下载二维码
	 * 
	 * */
	const onDownloadCode = (row) => {
		// 创建a标签
		const link = document.createElement('a');
		link.href = process.env.VUE_APP_API_HOST + `/charge/common/getqr?id=${row.id}`;
		link.click();
		URL.revokeObjectURL(link.href);
	}
	/**
	 * 
	 * 复制链接
	 * 
	 * */
	const onCopyLink = (row) => {
		const clipboard = new ClipboardJS('.copy-btn', {
			text() {
				return row.a_url;
			}
		});
		clipboard.on('success', () => {
			ElMessage.success("复制成功！");
			clipboard.destroy();  // 释放内存
		})
		clipboard.on('error', () => {
			ElMessage.error("复制失败！");
			clipboard.destroy();  // 释放内存
		})
	}
	/**
	 * 
	 * 新增活动成功重新获取列表数据
	 * 
	 * */
	const tableLoad = () => {
		roleTable.value.tableLoad();
	}

	onMounted(() => {
		getTypeLists();
		roleTable.value.tableLoad();
	});
</script>

<style lang="scss">
	.activity-announc {
		font-family: "Source Han Sans CN";

		.flex {
			display: flex;
		}

		.justify-between {
			justify-content: space-between;
		}

		.s-m-b {
			margin-bottom: 10px;
		}

		.top-title {
			height: 32px;
			line-height: 32px;
		}

		.vertical {
			border: 2px solid #1ACB95;
			margin-left: 0;
		}

		.preview-btn {
			width: 60px;
		}

		.el-table__footer-wrapper tbody td.el-table__cell {
			background-color: #fff;
			border-color: #fff;
		}

		.el-table__fixed::before,
		.el-table::before,
		.el-table__fixed-right::before {
			background-color: #fff;
		}

		.el-row {
			border: none;
		}

		.content {
			padding: 20px;
		}

		.add-dialog {
			.el-dialog__body {
				padding: 20px 20px 30px 30px;

				.el-table__row {
					.el-table__cell {
						padding: 9px 0 !important;
					}
				}

			}
		}
	}
</style>