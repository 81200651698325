<!-- 新增活动 -->

<template>
	<div>
		<w-dialog ref="addDialog" class="add-dialog" title="新增活动" width="62%" btnWidth="140px" top="10vh"
			confirmText="提交保存" @wConfirm="addConfirmBtn">
			<el-form class="add-form" ref="addForm" :model="ruleForm" :rules="rules" labelPosition="top">
				<el-row :gutter="20">
					<el-col :span="16">
						<div class="s-m-b">
							<el-divider direction="vertical" class="vertical" />
							<span class="top-title">基本信息</span>
						</div>

						<el-row :gutter="20">
							<el-col :span="12">
								<el-form-item label="活动名称" prop="a_name">
									<input class="update-input" v-model="ruleForm.a_name" maxlength="8"
										placeholder="请输入活动名称" />
								</el-form-item>
							</el-col>

							<el-col :span="12">
								<el-form-item label="活动时间" prop="a_time">
									<el-date-picker style="background: #f2f2f2;" v-model="ruleForm.a_time"
										:clearable="false" type="datetimerange" range-separator="-"
										start-placeholder="开始时间" end-placeholder="结束时间" format="YYYY-MM-DD HH:mm:ss"
										value-format="YYYY-MM-DD HH:mm:ss" :default-time="datePicker.defaultTime" />
								</el-form-item>
							</el-col>

							<el-col :span="12">
								<el-form-item label="活动单位" prop="a_unit">
									<el-select v-model="ruleForm.a_unit" clearable placeholder="请选择活动单位"
										@change="unitChange">
										<el-option label="平台" value="1"></el-option>
										<el-option label="商家" value="2"></el-option>
									</el-select>
								</el-form-item>
							</el-col>

							<el-col :span="12" v-if="ruleForm.a_unit == '1'">
								<el-form-item label="小程序" prop="a_sys_mini_id">
									<el-select v-model="ruleForm.a_sys_mini_id" clearable placeholder="请选择小程序">
										<el-option v-for="(item, index) in appletOption" :key="index"
											:label="item.mi_name" :value="item.a_sys_mini_id">
										</el-option>
									</el-select>
								</el-form-item>
							</el-col>

							<el-col :span="12" v-if="ruleForm.a_unit == '2'">
								<el-form-item label="电站" prop="a_station">
									<el-select v-model="ruleForm.a_station" clearable placeholder="请选择电站">
										<el-option v-for="(item, index) in powerStationOption" :key="index"
											:label="item.s_name" :value="item.s_id">
										</el-option>
									</el-select>
								</el-form-item>
							</el-col>

							<el-col :span="12">
								<el-form-item label="活动类型" prop="a_type">
									<el-select v-model="ruleForm.a_type" clearable placeholder="请选择活动类型">
										<el-option v-for="(item, index) in typeOption" :key="index"
											:label="item.at_name" :value="item.id">
										</el-option>
									</el-select>
								</el-form-item>
							</el-col>

							<el-col :span="12">
								<el-form-item label="抽奖用户" prop="ac_target">
									<el-select v-model="ruleForm.ac_target" clearable placeholder="请选择抽奖用户"
										@change="emptyCoupons">
										<el-option label="不限制" value="0"></el-option>
										<el-option label="新用户" value="1"></el-option>
										<el-option label="老用户" value="2"></el-option>
									</el-select>
								</el-form-item>
							</el-col>

							<el-col :span="12">
								<el-form-item label="抽奖次数" prop="ac_counttype">
									<el-select v-model="ruleForm.ac_counttype" clearable placeholder="请选择抽奖次数"
										@change="emptyCoupons">
										<el-option label="单日最高" value="1"></el-option>
										<el-option label="累计最高" value="2"></el-option>
									</el-select>
								</el-form-item>
							</el-col>

							<el-col :span="12" v-if="ruleForm.ac_counttype == '1'">
								<el-form-item label="单日最高次数" prop="ac_count">
									<el-input v-model="ruleForm.ac_count" placeholder="请输入单日最高抽奖次数" clearable
										@change="emptyCoupons">
									</el-input>
								</el-form-item>
							</el-col>

							<el-col :span="12" v-if="ruleForm.ac_counttype == '2'">
								<el-form-item label="累计最高次数" prop="ac_count">
									<el-input v-model="ruleForm.ac_count" placeholder="请输入累计最高抽奖次数" clearable
										@change="emptyCoupons">
									</el-input>
								</el-form-item>
							</el-col>

							<el-col :span="12">
								<el-form-item label="奖品" prop="ac_prize">
									<el-select v-model="ruleForm.ac_prize" clearable placeholder="请选择奖品"
										@change="prizeChange">
										<el-option label="优惠券" value="1"></el-option>
										<el-option label="其他" value="2"></el-option>
									</el-select>
									<div class="coupon-num" @click.stop="onCouponNum"
										v-if="ruleForm.prizelist.length > 0">
										+{{ couponNum }}
									</div>
								</el-form-item>
							</el-col>

							<el-col :span="24">
								<el-form-item label="活动规则" prop="a_rule">
									<el-input v-model="ruleForm.a_rule" type="textarea" rows="4" placeholder="请输入活动规则">
									</el-input>
								</el-form-item>
							</el-col>
						</el-row>
					</el-col>

					<el-col :span="8">
						<div class="flex justify-between">
							<div class="s-m-b">
								<el-divider direction="vertical" class="vertical" />
								<span class="top-title">活动信息</span>
							</div>
							<el-button type="primary" round size="small" plain class="cancle preview-btn"
								@click="onPreviewActivity">预览</el-button>
						</div>
						<div>
							<div class="s-m-b">
								<div class="s-m-b">banner图</div>
								<img-upload :limit="1" :fileList="bannerFile" @uploadFile="uploadBannerFile"
									@deleteFile="deleteBanner" suggestText="" :uploadTitle="'banner图'">
								</img-upload>
							</div>

							<div class="s-m-b">
								<div class="s-m-b">缩略图</div>
								<img-upload :limit="1" :fileList="thumbnailFile" @uploadFile="uploadThumbnailFile"
									@deleteFile="deleteThumbnail" suggestText="" :uploadTitle="'缩略图'">
								</img-upload>
							</div>

							<div class="s-m-b">
								<div class="s-m-b">详情图</div>
								<img-upload :limit="1" :fileList="detailedFile" @uploadFile="uploadDetailedFile"
									@deleteFile="deleteDetailed" suggestText="" :uploadTitle="'详情图'">
								</img-upload>
							</div>
							<el-form-item label="分享文案" prop="a_cwriter">
								<el-input v-model="ruleForm.a_cwriter" type="textarea" rows="4" placeholder="请输入分享文案">
								</el-input>
							</el-form-item>
						</div>
					</el-col>
				</el-row>
			</el-form>
		</w-dialog>

		<!-- 选择优惠券 -->
		<couponConfig ref="refCoupon" @assConfirm="assConfirm"></couponConfig>

		<!-- 预览 -->
		<previewActivity ref="previewRef"></previewActivity>
	</div>
</template>

<script setup>
	import {
		ref,
		reactive,
		computed,
		watch,
		nextTick,
	} from "vue";
	import { ActivityManage, PowerStations, } from "@/plugins/api.js";
	import { ElMessage, } from "element-plus";
	import { useStore } from "vuex";
	import imgUpload from "@/components/img-upload/img-upload.vue";
	import couponConfig from './couponConfig.vue';
	import previewActivity from './previewActivity.vue';

	const props = defineProps({
		tableLoad: {
			type: Function,
			default: null
		}
	})

	const store = useStore();
	const menuTokens = computed(() => store.state.menuToken.menuTokens);
	const qiniuData = computed(() => store.state.Config.qiniuData);
	const authData = ref([]);
	// 监听权限
	watch(() => menuTokens.value, (data) => {
		if (data.length) {
			authData.value = data;
		}
	}, {
		deep: true,
		immediate: true,
	})
	const addDialog = ref(null);  // 新增活动弹框对象
	const typeOption = ref([]);  // 活动类型选项
	const powerStationOption = ref([]);  // 电站选项
	const appletOption = ref([]);  // 小程序选项
	const bannerFile = ref([]);  // 上传banner图
	const thumbnailFile = ref([]);  // 上传缩略图
	const detailedFile = ref([]);  // 上传详情图
	const addForm = ref(null);  // 表单对象
	const refCoupon = ref(null);  // 优惠券配置对话框对象
	const previewRef = ref(null);  // 预览对象
	const couponNum = ref(0);  // 优惠券数量
	const datePicker = ref({  // 默认时间
		defaultTime: [
			new Date(2000, 1, 1, 0, 0, 0),
			new Date(2000, 1, 1, 23, 59, 59),
		],
	})
	/** 表单数据对象 */
	const ruleForm = ref(
		{
			a_name: '',  // 活动名称
			a_time: [],  // 活动时间
			a_stime: '',  // 活动开始时间
			a_etime: '',  // 活动结束时间
			a_type: '',  // 活动类型
			a_unit: '',  // 活动单位(1平台2商家)
			a_station: '',  // 如果活动单位是商家，关联的电站id
			a_sys_mini_id: '',  // 如果活动单位是平台，关联的小程序id
			ac_target: '',  // 抽奖用户0：全部用户1：新客户2：老客户
			ac_counttype: '',  // 抽奖次数类型（0：不限1：单日最高2：累计最高）
			ac_count: '',  // 抽奖次数
			ac_prize: '',  // 奖品类型（1：优惠券2：其他）
			a_rule: '',  // 活动规则
			a_cwriter: '',  // 分享文案
			filelist: [],  // 附件集合[{"af_name":'文件名',"key"=>"七牛地址","af_classify"=>1},.....](af_classify1：banner图2：缩略图3：详情图4：其他)
			prizelist: [],  // 奖品集合[{"ap_pname":'优惠券1',"ap_pid":"优惠券id","ap_count":奖品数量,"ap_probability":中奖概率},.....]
		}
	);
	/** 表单规则对象 */
	const rules = reactive({
		a_name: [{ required: true, message: "请输入活动名称", trigger: "blur", }],
		a_time: [{ required: true, message: "请选择活动时间", trigger: "blur", }],
		a_unit: [{ required: true, message: "请选择活动单位", trigger: "blur", }],
		a_station: [{ required: true, message: "请选择电站", trigger: "blur", }],
		a_type: [{ required: true, message: "请选择活动类型", trigger: "blur", }],
		a_sys_mini_id: [{ required: true, message: "请选择小程序", trigger: "blur", }],
		ac_target: [{ required: true, message: "请选择抽奖用户", trigger: "blur", }],
		ac_count: [{ required: true, message: "请输入次数", trigger: "blur", }],
		ac_counttype: [{ required: true, message: "请选择抽奖次数", trigger: "blur", }],
		ac_prize: [{ required: true, message: "请选择奖品", trigger: "blur", }],
		a_rule: [{ required: true, message: "请输入活动规则", trigger: "blur", }],
		a_cwriter: [{ required: true, message: "请输入分享文案", trigger: "blur", }],
	});
	/**
	 * 
	 * 显示对话框
	 * 
	 * **/
	const openAddActivity = () => {
		getTypeLists();
		getPowerStationLists();
		getAppletLists();
		addDialog.value.show();
		store.dispatch("getQiniuData");
		ruleForm.value.a_name = '';
		ruleForm.value.a_time = [];
		ruleForm.value.a_stime = '';
		ruleForm.value.a_etime = '';
		ruleForm.value.a_type = '';
		ruleForm.value.a_unit = '';
		ruleForm.value.a_station = '';
		ruleForm.value.a_sys_mini_id = '';
		ruleForm.value.ac_target = '';
		ruleForm.value.ac_counttype = '';
		ruleForm.value.ac_count = '';
		ruleForm.value.ac_prize = '';
		ruleForm.value.a_rule = '';
		ruleForm.value.a_cwriter = '';
		ruleForm.value.filelist = [];
		ruleForm.value.prizelist = [];
		bannerFile.value = [];
		thumbnailFile.value = [];
		detailedFile.value = [];
		nextTick(() => {
			addForm.value.resetFields();
		})
	}
	/**
	 * 
	 * 获取活动类型
	 * 
	 * **/
	const getTypeLists = () => {
		ActivityManage.getTypeLists({}).then((res) => {
			if (res.Code == 200) {
				typeOption.value = res.Data ? res.Data : [];
			}
		}).catch(() => { });
	}
	/**
	 * 
	 * 获取小程序
	 * 
	 * **/
	const getAppletLists = () => {
		ActivityManage.getMiniProgramsLists({}).then((res) => {
			if (res.Code == 200) {
				appletOption.value = res.Data ? res.Data : [];
			}
		}).catch(() => { });
	}
	/**
	 * 
	 * 获取电站数据
	 * 
	 * */
	const getPowerStationLists = () => {
		PowerStations.getPowerCommonStationList().then((res) => {
			if (res.Code === 200) {
				powerStationOption.value = res.Data ? res.Data : [];
			} else {
				ElMessage.error(res.Message);
			}
		});
	}
	/**
	 * 
	 * 活动单位选择变化
	 * 
	 * */
	const unitChange = () => {
		ruleForm.value.a_station = '';
		ruleForm.value.a_sys_mini_id = '';
		emptyCoupons();
	}
	/**
	 * 
	 * 清空奖品选择优惠券
	 * 
	 * */
	const emptyCoupons = () => {
		if (ruleForm.value.ac_prize == '1') {
			ruleForm.value.ac_prize = '';
			ruleForm.value.prizelist = [];
		}
	}
	/**
	 * 
	 * 奖品选择变化
	 * 
	 * */
	const prizeChange = () => {
		// 如果选择的是优惠券
		if (ruleForm.value.ac_prize == '1') {
			// 验证必填项
			if (ruleForm.value.a_unit == '') {
				ElMessage.error("请选择活动单位！");
				ruleForm.value.ac_prize = '';
				return false;
			} else if (ruleForm.value.ac_target == '') {
				ElMessage.error("请选择抽奖用户！");
				ruleForm.value.ac_prize = '';
				return false;
			} else if (ruleForm.value.ac_counttype == '') {
				ElMessage.error("请选择抽奖次数类型！");
				ruleForm.value.ac_prize = '';
				return false;
			} else if (ruleForm.value.ac_count == '') {
				ElMessage.error("请输入抽奖次数！");
				ruleForm.value.ac_prize = '';
				return false;
			}
			// 活动单位选择商家，必须选择电站
			if (ruleForm.value.a_unit == '2') {
				if (ruleForm.value.a_station == '') {
					ElMessage.error("请选择电站！");
					ruleForm.value.ac_prize = '';
					return false;
				}
			}
			// 打开优惠券配对话框
			refCoupon.value.openDialog(ruleForm.value, 'add');
		} else {
			ruleForm.value.prizelist = [];
		}
	}
	/**
	 * 
	 * 选择优惠券
	 * 
	 * */
	const assConfirm = (data) => {
		couponNum.value = data.length;
		ruleForm.value.prizelist = data;
	}
	/**
	 * 
	 * 点击优惠券数量
	 * 
	 * */
	const onCouponNum = () => {
		refCoupon.value.openDialog(ruleForm.value, 'edit');
	}
	/**
	 * 
	 * 上传banner图
	 * 
	 * */
	const uploadBannerFile = (data) => {
		bannerFile.value.push({
			file_url: data.standard_url,
			key: data.key,
			af_name: data.filename,
			af_classify: 1,
		});
	};
	/**
	 * 
	 * 文件删除
	 * 
	 * */
	const deleteBanner = (data) => {
		bannerFile.value = [];
	};
	/**
	 * 
	 * 上传缩略图
	 * 
	 * */
	const uploadThumbnailFile = (data) => {
		thumbnailFile.value.push({
			file_url: data.standard_url,
			key: data.key,
			af_name: data.filename,
			af_classify: 2,
		});
	};
	/**
	 * 
	 * 文件删除
	 * 
	 * */
	const deleteThumbnail = (data) => {
		thumbnailFile.value = [];
	};
	/**
	 * 
	 * 上传详情图
	 * 
	 * */
	const uploadDetailedFile = (data) => {
		detailedFile.value.push({
			file_url: data.standard_url,
			key: data.key,
			af_name: data.filename,
			af_classify: 3,
		});
	};
	/**
	 * 
	 * 文件删除
	 * 
	 * */
	const deleteDetailed = (data) => {
		detailedFile.value = [];
	};
	/**
	 * 
	 * 新增活动确定按钮
	 * 
	 * **/
	const addConfirmBtn = () => {
		addForm.value.validate((valid) => {
			if (valid) {
				if (bannerFile.value.length == 0) {
					ElMessage.error("请上传banner图！");
					return false;
				} else if (thumbnailFile.value.length == 0) {
					ElMessage.error("请上传缩略图！");
					return false;
				} else if (detailedFile.value.length == 0) {
					ElMessage.error("请上传详情图！");
					return false;
				}
				ruleForm.value.a_stime = ruleForm.value.a_time[0];
				ruleForm.value.a_etime = ruleForm.value.a_time[1];
				ruleForm.value.filelist = JSON.stringify([...bannerFile.value, ...thumbnailFile.value, ...detailedFile.value]);
				if (ruleForm.value.prizelist.length > 0) {
					ruleForm.value.prizelist.forEach(item => {
						item.ap_count = item.surplus_nume;
					})
				}
				ruleForm.value.prizelist = JSON.stringify(ruleForm.value.prizelist);
				addDialog.value.isLoading = true; // 按钮加载中状态
				ActivityManage.addActivity(ruleForm.value).then((res) => {
					addDialog.value.isLoading = false;
					if (res.Code === 200) {
						ElMessage.success('新增成功！'); // 成功提示
						addDialog.value.close(); // 关闭弹框
						props.tableLoad()
					} else {
						ruleForm.value.prizelist = JSON.parse(ruleForm.value.prizelist);
						ruleForm.value.filelist = JSON.parse(ruleForm.value.filelist);
						ElMessage.error(res.Message);
					}
				}).catch(() => {
					// console.log("err", err)
				});
			}
		});
	}
	/**
	 * 
	 * 预览按钮
	 * 
	 * **/
	const onPreviewActivity = () => {
		addForm.value.validate((valid) => {
			if (valid) {
				if (bannerFile.value.length == 0) {
					ElMessage.error("请上传banner图！");
					return false;
				} else if (thumbnailFile.value.length == 0) {
					ElMessage.error("请上传缩略图！");
					return false;
				} else if (detailedFile.value.length == 0) {
					ElMessage.error("请上传详情图！");
					return false;
				}
				ruleForm.value.filelist = [...bannerFile.value, ...thumbnailFile.value, ...detailedFile.value];
				previewRef.value.openDialog(ruleForm.value);
			}
		});
	}
	defineExpose({
		openAddActivity,
	})
</script>

<style lang="scss">
	.flex {
		display: flex;
	}

	.justify-between {
		justify-content: space-between;
	}

	.s-m-b {
		margin-bottom: 10px;
	}

	.top-title {
		height: 32px;
		line-height: 32px;
	}

	.vertical {
		border: 2px solid #1ACB95;
		margin-left: 0;
	}

	.preview-btn {
		width: 60px;
		height: 25px;
	}

	.update-input {
		border: none;
		width: 100%;
		background-color: var(--search-uncheck-bg-color);
		border: none;
		color: var(--text-color);
		font-weight: 550;
		border-radius: 4px;
		line-height: 1px !important;
		height: 40px;
		outline: 0;
		padding: 0 15px;
	}

	.el-table__footer-wrapper tbody td.el-table__cell {
		background-color: #fff;
		border-color: #fff;
	}

	.el-table__fixed::before,
	.el-table::before,
	.el-table__fixed-right::before {
		background-color: #fff;
	}

	.el-row {
		border: none;
	}

	.content {
		padding: 20px;
	}

	.add-dialog {
		.el-dialog__body {
			padding: 20px 20px 30px 30px;

			.el-table__row {
				.el-table__cell {
					padding: 9px 0 !important;
				}
			}

		}
	}

	.coupon-num {
		z-index: 999;
		height: 20px;
		width: 30px;
		border-radius: 50%;
		color: #1ACB95;
		text-align: center;
		line-height: 20px;
		margin-left: 5px;
		position: absolute;
		left: 60px;
		top: 11px;
		cursor: pointer;
	}
</style>