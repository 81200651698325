<!-- 优惠券配置 -->

<template>
	<div class="power-detail-dialog">
		<w-dialog ref="dialogRef" title="优惠券配置" width="60%" top="6vh" @wConfirm="addConfirm" :modalClick="false"
			:confirmText="isAdd ? '确认添加' : '确认'">
			<div v-show="isAdd">
				<el-table ref="tableref" :data="tableDatas" stripe style="width: 100%" element-loading-text="数据加载中"
					tooltip-effect="light" @selection-change="handleSelectionChange" height='450' :row-key="getRowKeys">
					<el-table-column type="selection" width="55" :reserve-selection="true" />
					<el-table-column prop="ap_pname" label="优惠券名称" show-overflow-tooltip />
					<el-table-column prop="f_use_time" label="使用有效期" show-overflow-tooltip />
					<el-table-column label="优惠券类型" show-overflow-tooltip width="150">
						<template #default="scope">
							<div
								:class="{'green-tag':scope.row.c_ct_id == 1,'blue-tag':scope.row.c_ct_id == 2,'orange-tag':scope.row.c_ct_id == 3}">
								<span v-if="scope.row.c_ct_id == 1">{{scope.row.f_coutype}}</span>
								<span v-if="scope.row.c_ct_id == 2">{{scope.row.f_coutype}}</span>
								<span v-if="scope.row.c_ct_id == 3">{{scope.row.f_coutype}}</span>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<div class="s-m-t">已选<span class="select-quant title-num-green">{{ multipleSelection.length }}</span>
				</div>
			</div>

			<div v-show="!isAdd">
				<div class="s-m-b text-end">
					<el-button type="primary" @click="onAddCoupons" style="width: 110px;">添加优惠券</el-button>
				</div>
				<el-table ref="electionRef" :data="selectedTableDatas" stripe style="width: 100%"
					element-loading-text="数据加载中" tooltip-effect="light" height='450'>
					<el-table-column prop="ap_pname" label="优惠券" show-overflow-tooltip />
					<el-table-column prop="surplus_nume" label="抽奖数量" show-overflow-tooltip />
					<!-- <el-table-column label="抽奖数量" show-overflow-tooltip>
						<template #default="scope">
							<div class="flex">
								<el-input type="number" v-model="scope.row.surplus_nume" placeholder="请输入"
									class="selected-input" oninput="if(value<0)value=0"></el-input>
								<div style="margin-top: 10px;">
									<el-icon color="#58D3AD">
										<EditPen style="width: 20px;height: 20px;" />
									</el-icon>
								</div>
							</div>
						</template>
					</el-table-column> -->
					<el-table-column label="中奖概率" show-overflow-tooltip>
						<template #default="scope">
							<div class="flex">
								<el-input type="number" v-model="scope.row.ap_probability" placeholder="请输入"
									class="selected-input" oninput="if(value<0)value=0">
									<template #suffix>
										<el-icon class="el-input__icon">%</el-icon>
									</template>
								</el-input>
								<div style="margin-top: 10px;">
									<el-icon color="#58D3AD">
										<EditPen style="width: 20px;height: 20px;" />
									</el-icon>
								</div>
							</div>

						</template>
					</el-table-column>
					<el-table-column fixed="right" label="操作" width="120">
						<template #default="scope">
							<el-button type="primary" size="small" plain class="cancle del-btn"
								@click="onDelete(scope.row, scope.$index)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</w-dialog>
	</div>
</template>

<script setup>
	import { ref, nextTick, } from "vue";
	import { ActivityManage, } from "@/plugins/api.js";
	import { ElMessage, } from "element-plus";
	import { EditPen, } from "@element-plus/icons-vue";

	// 弹框对象
	const dialogRef = ref(null);
	const tableref = ref(null);
	const tableDatas = ref([]); // 表格数据
	const multipleSelection = ref([]); // 选中优惠券
	const ruleForm = ref({});  // 活动表单填写数据
	const isAdd = ref(true);  // 是否新增
	const emit = defineEmits(['assConfirm']);
	const selectedTableDatas = ref([]);  // 已选择优惠券表格数据
	/**
	 * 
	 * 打开弹框
	 * 
	 * */
	const respId = ref([])
	const openDialog = (data) => {
		ruleForm.value = data;
		isAdd.value = true;
		multipleSelection.value = [];
		selectedTableDatas.value = [];
		getPlatformList();
		dialogRef.value.show();
	};
	/**
	 * 
	 * 获取优惠券列表数据
	 * 
	 * */
	const getPlatformList = () => {
		ActivityManage.getCopon({
			a_unit: ruleForm.value.a_unit,  // 活动单位类型1平台2商家
			a_station: ruleForm.value.a_station,  // 活动电站（当活动单位类型为商家时必传）
			ac_target: ruleForm.value.ac_target,  // 抽奖用户（0：全部用户1：新客户2：老客户）
			ac_counttype: ruleForm.value.ac_counttype,  // 抽奖次数类型1：单日最高2：累计最高
			ac_count: ruleForm.value.ac_count,  // 抽奖次数

			// a_unit: 1,  // 活动单位类型1平台2商家
			// a_station: '',  // 活动电站（当活动单位类型为商家时必传）
			// ac_target: 0,  // 抽奖用户（0：全部用户1：新客户2：老客户）
			// ac_counttype: 2,  // 抽奖次数类型1：单日最高2：累计最高
			// ac_count: 10,  // 抽奖次数
		}).then((res) => {
			if (res.Code === 200) {
				tableDatas.value = res.Data.list ? res.Data.list : [];
				if (tableDatas.value.length > 0) {
					tableDatas.value.forEach(item => {
						item.ap_probability = '';
					})
				}
				if (ruleForm.value.prizelist.length > 0) {
					selectedTableDatas.value = ruleForm.value.prizelist;
					multipleSelection.value = ruleForm.value.prizelist;
					isAdd.value = false;
				}
			} else {
				ElMessage.error(res.Message);
			}
		});
	}
	/**
	 * 
	 * 选择优惠券
	 * 
	 * */
	const handleSelectionChange = (val) => {
		multipleSelection.value = val;
	}
	/**
	 * 
	 * 添加优惠券按钮
	 * 
	 * */
	const onAddCoupons = () => {
		isAdd.value = true;
		nextTick(() => {
			tableref.value.clearSelection();
			tableDatas.value.forEach(item => {
				// 回显已选优惠券
				if (selectedTableDatas.value.length > 0) {
					selectedTableDatas.value.forEach(list => {
						if (item.ap_pid == list.ap_pid) {
							tableref.value.toggleRowSelection(item, true);
						}
					})
				}
			})
		})
	}
	/**
	 * 
	 * 删除已选优惠券按钮
	 * 
	 * */
	const onDelete = (row, i) => {
		selectedTableDatas.value.splice(i, 1);
	}
	/**
	 * 
	 * 确认新增按钮
	 * 
	 * */
	const addConfirm = () => {
		if (multipleSelection.value.length == 0) {
			ElMessage.error("请选择优惠券！");
			return false;
		}
		if (isAdd.value) {
			selectedTableDatas.value = [];
			// 新增优惠券
			isAdd.value = false;
			multipleSelection.value.forEach(item => {
				selectedTableDatas.value.push(item);
			})
		} else {
			// 确定添加
			for (let i = 0; i < selectedTableDatas.value.length; i++) {
				if (selectedTableDatas.value[i].ap_probability == '') {
					ElMessage.error("请填写中奖概率！");
					return false;
				}
				//  else if (selectedTableDatas.value[i].surplus_nume == '') {
				// 	ElMessage.error("请填写抽奖次数！");
				// 	return false;
				// }
			}
			dialogRef.value.close();
			emit("assConfirm", selectedTableDatas.value);
		}
	}
	/**
	 * 
	 * 取消按钮
	 * 
	 * */
	// const wCancel = () => {
	// 	if (isAdd.value) {
	// 		dialogRef.value.close();
	// 	} else {
	// 		isAdd.value = true;
	// 	}
	// }
	const getRowKeys = (row) => {
		return row.ap_pid;
	}
	defineExpose({
		openDialog
	})
</script>

<style lang="scss" scoped>
	.del-btn {
		border: none;
		color: red;
	}

	.flex {
		display: flex;
	}

	.s-m-b {
		margin-bottom: 10px;
	}

	.text-end {
		text-align: end;
	}

	.green-tag {
		background-color: #1AC994;
		color: #fff;
		text-align: center;
		border-radius: 5px;
	}

	.orange-tag {
		background-color: #ffaa00;
		color: #fff;
		text-align: center;
		border-radius: 5px;
	}

	.blue-tag {
		background-color: #00aaff;
		color: #fff;
		text-align: center;
		border-radius: 5px;
	}

	.selected-input {
		width: 100px;
	}

	.el-input__icon.el-icon {
		color: #000;
	}
</style>