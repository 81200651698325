<template>
	<w-dialog ref="refWdialog" class="add-dialog" title="使用合计" width="70%" btnWidth="140px" top="3vh"
		:hideFooter="true">
		<div>
			<div class="statistics-head">
				<el-row :gutter="10">
					<el-col :span="8">
						<div class="bg-grey statistics-left">
							<div class="flex s-m-t">
								<div class="statistics-left-title text-hidden">{{ info.c_title }}</div>
								<div class="statistics-left-tag" v-if="info.coupon_money_mark">{{ info.coupon_money_mark }}</div>
							</div>
							<div class="flex align-center s-m-t" v-if="info.special_range">
								<div class="dots bg-pale-orange"></div>
								<div>{{ info.special_range }}</div>
							</div>
							<div class="flex align-center xs-m-t" v-if="info.plat_title">
								<div class="dots bg-pale-orange"></div>
								<div>{{ info.plat_title }}</div>
							</div>
							<div class="flex align-center xs-m-t" v-if="info.special_way">
								<div class="dots bg-pale-orange"></div>
								<div>{{ info.special_way }}</div>
							</div>
						</div>
					</el-col>
					<el-col :span="16">
						<div class="bg-grey group-center">
							<div class="flex statistics-right" style="justify-content: space-around;width: 100%;">
								<div v-for="(item,i) in statistics" :key="i">
									<div class="statistics-nums">{{ item.nums }}</div>
									<div class="statistics-text">{{ item.title }}</div>
								</div>
							</div>
						</div>
						<div class="remark-content">备注说明：{{ info.c_remark ? info.c_remark : '无' }}</div>
					</el-col>
				</el-row>
			</div>
			<!-- <el-row :gutter="20" class="statistics-head">
				<el-col :span="6">
					<div>{{info.c_title}}</div>
					<div class="flex padding-top">
						<el-button class="small text-white" v-if="info.coupon_money">{{info.coupon_money}}</el-button>
						<el-button type="warning" class="small text-white">{{info.plat_title}}</el-button>
					</div>
				</el-col>
				<el-col :span="18">
					<div class="group-center">
						<div class="flex" style="justify-content: space-around;width: 100%;">
							<div v-for="(item,i) in statistics" :key="i">
								<div class="statistics-nums">{{item.nums}}</div>
								<div class="statistics-text">{{item.title}}</div>
							</div>
						</div>
					</div>
				</el-col>
			</el-row>
			<div v-if="info.c_remark" class="remark-content">备注说明：{{ info.c_remark }}</div>
			<el-radio-group v-model="list_type" @change="changeList" style="margin-bottom: 10px">
				<el-radio-button label="1">使用明细</el-radio-button>
				<el-radio-button label="2">未使用明细</el-radio-button>
			</el-radio-group> -->
			<div class="flex">
				<div v-for="(item, i) in tabDatas" :key="i" class="tab-info" :class="activeIndex == i ? 'tab-active' : ''" @click="changeList(item, i)">{{ item.name }}</div>
			</div>
			<div class="content">
				<common-table ref="roleTable" tableHeight="calc(100vh - 555px)" :ischeck="false" :ispaging="true"
					:extraParame="extraParame" :apiName="Coupons.couponsInfo" :filters="filters"
					:columns="tableColumns">
					<template #custom v-if="list_type == 1">
						<div style="line-height: 40px;">收款时间：</div>
						<el-date-picker style="background: #f2f2f2;width: 300px;" v-model="use_time" @change="changeTime" type="daterange"  range-separator="至"
							start-placeholder="开始时间" end-placeholder="结束时间" />
					</template>
					<template  #cu_stime="{row}" v-if="list_type == 2">
						<div>{{row.cu_stime}}-{{row.cu_etime}}</div>
					</template>
				</common-table>
			</div>
		</div>
	</w-dialog>

</template>

<script setup>
	import {
		ref
	} from 'vue';

	import {
		Coupons
	} from '@/plugins/api.js'

	import {
		nextTick
	} from 'vue';
	
	import {
		timeFormat
	} from '@/utils/common.js'
	
	const use_time = ref('')
	const changeTime = () =>{
		if (use_time.value) {
			extraParame.value.start_time = timeFormat(use_time.value[0])
			extraParame.value.end_time = timeFormat(use_time.value[1])
			roleTable.value.tableLoad()
		}else{
			extraParame.value.start_time = ''
			extraParame.value.end_time = ''
		}
		roleTable.value.tableLoad()
	}

	const tabDatas = ref([
		{ id: '1', name: '使用明细' },
		{ id: '2', name: '未使用明细' },
	]);
	const activeIndex = ref('0');

	const statistics = ref([{
			nums: '',
			title: '生成数量'
		},
		{
			nums: '',
			title: '领用合计'
		},
		{
			nums: '',
			title: '使用合计'
		},
		{
			nums: '',
			title: '抵扣订单服务费合计'
		},
		{
			nums: '',
			title: '优惠券优惠合计'
		}
	])
	let filterTable = [
		{
			filterType: "input",
			name: "keywords",
			value: "",
			placeholder: "输入订单编号或者用户手机号查询",
			with:200
		}
	]

	const filters = ref([])
	filters.value = filterTable
	let useTable = [{
			prop: "or_no",
			label: "订单编号",
			color: "--text-color",
			showTooltip: true,
			minWidth: 150
		},
		{
			prop: "s_name",
			label: "使用电站",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "or_money",
			label: "订单金额",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "orw_elec_money",
			label: "电费",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "orw_sevice_money",
			label: "服务费",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "orc_amount",
			label: "优惠金额",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "cu_no",
			label: "优惠券码",
			color: "--text-color",
			showTooltip: true,
		},
		{
			prop: "orw_system_check_time",
			label: "收款时间",
			color: "--text-color",
			showTooltip: true,
		}
	]
	
	
	/** 表格配置数据 */
	const tableColumns = ref([])
	tableColumns.value = useTable

	const refWdialog = ref(null)
	const c_id = ref('')
	const list_type = ref('1')
	const roleTable = ref(null)
	const show = (id) => {
		c_id.value = id
		extraParame.value.c_id = c_id.value
		list_type.value = '1';
		activeIndex.value = '0';
		filters.value = filterTable;
		extraParame.value.list_type = '1';
		getCouponsInfo('info')
		tableColumns.value = useTable
		refWdialog.value.show()
		nextTick(() => {
			roleTable.value.tableLoad()
		})
	}

	const info = ref('')
	const getCouponsInfo = (type) => {
		let reqData = {
			c_id: c_id.value,
			list_type: list_type.value,
			type: type,
			limit: '15'
		}
		Coupons.couponsInfo(reqData).then(res => {
			if (res.Code == 200) {
				if (type == 'info') {
					info.value = res.Data;
					statistics.value[0].nums = info.value.c_num;
					statistics.value[1].nums = `${info.value.get_num}(${info.value.get_percent}%)`;
					statistics.value[2].nums = `${info.value.use_num}(${info.value.use_percent}%)`;
					statistics.value[3].nums = `${info.value.order_service_total}(元)`;
					statistics.value[4].nums = `${info.value.order_coupon_amount}(元)`;
				}
			}

		})
	}
	const changeList = (item, i) => {
		activeIndex.value = i;
		list_type.value = item.id;
		extraParame.value.list_type = list_type.value;
		if (list_type.value == 1) {
			filters.value = filterTable
			tableColumns.value = useTable
		}
		if (list_type.value == 2) {
			filters.value = []
			tableColumns.value = [{
				prop: "cu_no",
				label: "优惠券码",
				color: "--text-color",
				showTooltip: true,
			}, {
				prop: "m_mobile",
				label: "领用用户",
				color: "--text-color",
				showTooltip: true,
			}, {
				type:'customRender',
				prop: "cu_stime",
				label: "有效期",
				color: "--text-color",
				showTooltip: true,
			}, {
				prop: "status",
				label: "状态",
				color: "--text-color",
				showTooltip: true,
			}]
		}
		nextTick(() => {
			roleTable.value.tableLoad()
		})

	}
	const extraParame = ref({
		c_id: '',
		list_type: '',
		type: 'list',
		limit: '15',
		start_time:'',
		end_time:''
	})
	defineExpose({
		show
	})
</script>

<style lang="scss" scoped >
	.content {
		padding: 15px 0 !important;
		.el-date-editor {
			background: var(--search-uncheck-bg-color) !important;
			.el-range-input{
				background-color: var(--search-uncheck-bg-color) !important;
			}
		}
	}

	.remark-content {
		background-color: #fff8e6;
		color: var(--warning-color);
		padding: 17px 20px;
		border-radius: 4px;
	}
	
	.is-active{
		background-color: rgba(26, 201, 148, 1) !important;
		color: #ffffff !important;
		border-radius: 5px 0 0 5px;
	}
	

	.el-radio-button__inner {
		background-color: rgba(26, 201, 148, 0.1) !important;
		// color: rgba(26, 201, 148, 1) !important;
		border: 1px solid rgba(26, 201, 148, 0.1) !important;
	}

	.padding-top {
		padding-top: 10px;
	}

	.small {
		padding: 0 5px !important;
		min-height: 30px !important;
	}

	.group-center {
		display: flex;
		align-items: center;
		margin-bottom: 10px;
	}
	.el-row {
		border: none;
		margin: 0;
	}

	.text-white {
		color: #fff;
	}

	.statistics-head {
		margin-bottom: 25px;
		margin-left: 0 !important;
		margin-right: 0 !important;
	}

	.statistics-nums {
		color: var(--theme-color);
		text-align: center;
		font-weight: bold;
		font-size: 18px;
	}

	.statistics-text {
		text-align: center;
		color: #5D6066;
		padding-top: 5px;
		font-size: 14px;
	}

	.bg-grey {
		background-color: #F3F4F8;
		border-radius: 8px;
	}

	.statistics-left {
		padding: 10px 15px;
		height: 100%;
	}

	.statistics-left-tag {
		background-color: #13BC8B;
		border-radius: 4px;
		font-size: 14px;
		color: #FFFFFF;
		line-height: 26px;
		padding: 0 16px;
		margin-left: 10px;
		flex-shrink: 0;
	}

	.statistics-left-title {
		color: #262C30;
		font-size: 20px;
		font-weight: bold;
	}

	.statistics-right {
		padding: 19px 0;
	}

	.tab-info {
		border-radius: 4px;
		font-size: 14px;
		cursor: pointer;
		color: #13BC8B;
		background-color: rgba(19, 188, 139, 0.1);
		padding: 6px 0;
		min-width: 88px;
    	text-align: center;
	}

	.tab-active {
		color: #FFFFFF;
		background-color: #13BC8B;
	}
</style>